import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import HeroPage from "./containers/HeroPage";
import AboutUs from "./containers/AboutUs";
import WorkedFor from "./containers/WorkedFor";
import Recommendations from "./containers/Recommendations";
import Footer from "./containers/Footer";
import "./App.css";
import { Box, Divider } from "@mui/material";
import Bridge from "./containers/Bridge";
import Navbar from "./containers/Navbar";
import Testimonials from "./containers/Testimonials";
import PricingComponent from "./containers/PricingComponent";
import { useScroll, useSpring, useTransform, motion } from "framer-motion";

function App() {
	const [pageIndex, setPageIndex] = useState(0);
	const [canScroll, setCanScroll] = useState(true);
	const sections = [
		<HeroPage />,
		<AboutUs />,
		<WorkedFor />,
		<Recommendations />,
		<Footer />,
	];
	const ref = useRef(null);

	const handleWheel = (event) => {
		if (!canScroll) return;

		if (event.deltaY > 0 && pageIndex < sections.length - 1) {
			setPageIndex((prevIndex) => prevIndex + 1);
		} else if (event.deltaY < 0 && pageIndex > 0) {
			setPageIndex((prevIndex) => prevIndex - 1);
		}
		setCanScroll(false);
		setTimeout(() => setCanScroll(true), 1000);
	};

	useEffect(() => {
		window.addEventListener("wheel", handleWheel, { passive: true });

		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, [pageIndex, canScroll]);

	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ["start end", "end end"],
	}); // Hook to get scroll progress
	const scale = useSpring(useTransform(scrollYProgress, [0, 1], [1, 1.2]), {
		stiffness: 40,
		damping: 90,
	});
	const rotate = useSpring(useTransform(scrollYProgress, [0, 1], [0, 20]), {
		stiffness: 40,
		damping: 90,
	});

	return (
		<ThemeProvider theme={theme}>
			<div className="container">
				<Navbar />
				<div
					className="container"
					style={{ background: theme.palette.background.default }}
				>
					{/* <motion.div
							key={pageIndex}
							initial={{ opacity: 0, y: 100 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -100 }}
							transition={{ duration: 0.8 }} // Adjusted duration for smoother transitions
							className="section"
						>
							{sections[pageIndex]}
						</motion.div> */}
					<HeroPage />

					<Bridge />
					<AboutUs />
					<Testimonials />
					{/* <WorkedFor /> */}
					<Box sx={{ py: 8 }} display={"flex"} justifyContent="center">
						<motion.img
							ref={ref}
							src="/assets/clientes.svg" // Updated to use the uploaded image
							alt="Clientes"
							style={{
								scale,
								rotate,
								width: "30rem",
								willChange: "transform",
							}}
						/>
					</Box>
					<PricingComponent />
					<Recommendations />
					<Footer />
				</div>
			</div>
		</ThemeProvider>
	);
}

export default App;
