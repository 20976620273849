import React, { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

const logos = [
	{
		id: 1,
		src: "./assets/credicorpbank_logo.png",
		alt: "Client Logo 1",
		link: "https://www.credicorpbank.com",
	},
	{
		id: 2,
		src: "./assets/nexo_logo.svg",
		alt: "Client Logo 2",
		link: "https://www.nexo.com.pa",
	},
	// Add more logos as needed
];

const WorkedFor = () => {
	const theme = useTheme();
	const ref = useRef(null);
	// Animation variants for the container
	const containerVariants = {
		initial: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { staggerChildren: 0.1, delayChildren: 0.3 },
		},
	};

	// Animation variants for logos
	const logoVariants = {
		initial: { y: 20, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
			transition: { type: "spring", stiffness: 100 },
		},
	};

	// Responsive styling for logos, with a more dynamic approach to colors
	const logoStyle = (isHover) => ({
		width: "100%",
		"@media (maxWidth: 600px)": {
			maxWidth: "150px",
		},
		"@media (minWidth: 601px) and (maxWidth: 960px)": {
			maxWidth: "200px",
		},
		"@media (minWidth: 961px) and (maxWidth: 1280px)": {
			maxWidth: "250px",
		},
		"@media (minWidth: 1281px)": {
			maxWidth: "400px",
		},
		height: "auto", // Maintain aspect ratio
		filter: isHover ? "none" : "grayscale(100%) brightness(50%)",
		transition: "all 0.3s ease-in-out",
		"&:hover": {
			filter: "grayscale(0%) brightness(100%)",
			transform: "scale(1.05)",
		},
	});

	return (
		<Box
			sx={{
				my: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "hidden",
			}}
		>
			<Typography
				variant="h3"
				textAlign="center"
				mb={5}
				fontWeight="bold"
				color={theme.palette.text.primary}
			>
				Clientes Satisfechos
			</Typography>
			<Box
				display={"flex"}
				alignItems={"center"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignContent={"center"}
				gap={5}
				maxWidth={"100%"}
			>
				<motion.div
					variants={containerVariants}
					initial="initial"
					animate="visible"
					sx={{
						flexGrow: 1,
					}}
				>
					<Grid container alignItems="center" gap={5} justifyContent={"center"}>
						{logos.map((logo) => (
							<Grid
								item
								xs={6}
								sm={4}
								md={4}
								lg={3}
								key={logo.id}
								component={motion.div}
								variants={logoVariants}
							>
								<a href={logo.link} target="_blank" rel="noreferrer">
									<motion.img
										src={logo.src}
										alt={logo.alt}
										style={logoStyle()}
										whileHover={{
											filter: "grayscale(0%) brightness(100%)",
											scale: 1.05,
										}}
									/>
								</a>
							</Grid>
						))}
					</Grid>
				</motion.div>
			</Box>
		</Box>
	);
};

export default WorkedFor;
