import { Typography } from "@mui/material";
import React from "react";

const LogoText = ({
	fontFamily = "League Spartan",
	fontWeight = "800",
	letterSpacing = "-12px",
	color = "#01010",
	fontSize = "10rem",
	shadow = false,
	subtitle = false,
}) => {
	return (
		<>
			<Typography
				variant={"h1"}
				fontFamily={fontFamily}
				fontWeight={fontWeight}
				letterSpacing={letterSpacing}
				color={color}
				sx={{ textShadow: shadow && shadow }}
				fontSize={fontSize}
			>
				Bridge.
			</Typography>
			{subtitle && (
				<Typography
					variant={"subtitle1"}
					fontSize={`calc(${fontSize} - 8rem)`}
					color={color}
				>
					Digital Solutions
				</Typography>
			)}
		</>
	);
};

export default LogoText;
