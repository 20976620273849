import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#553A41", // Dark reddish-brown, drawn from the canyon walls
			light: "#8F6F71", // Lighter shade of the primary color
			dark: "#351F21", // Darker shade for contrast
		},
		secondary: {
			main: "#A67F8E", // A soft pinkish tone that resonates with the canyon's hue
			light: "#C9A9B3", // Lighter shade for secondary buttons and accents
			dark: "#765661", // Darker shade for hover states
		},
		tertiary: {
			main: "#D9CAB3", // A pale earth tone for background elements
			contrastText: "#4E3D42", // Dark contrasting text for readability on tertiary backgrounds
		},
		background: {
			default: "#EDE7E3", // Light earth tone providing a neutral canvas for content
			paper: "#EDE7E3", // Off-white for cards and paper elements
		},
		text: {
			primary: "#4E3D42", // A deep brown that stands out against lighter backgrounds
			secondary: "#6F585A", // Medium brown for less emphasis but still legible
		},
	},
	typography: {
		fontFamily: '"League Spartan", "Helvetica Neue", Arial, sans-serif',
		h1: {
			fontWeight: 700,
			letterSpacing: "-0.1em",
			fontSize: "4rem", // Scaled down for balance with the new aesthetic
		},
		h2: {
			fontWeight: 700,
			fontSize: "3rem", // Scaled down for better visual hierarchy
			letterSpacing: "-0.05em",
		},
		body: {
			fontWeight: 200,
			letterSpacing: "-0.02em",
			fontSize: "1.4rem", // Scaled down for better visual hierarchy
		},
		button: {
			textTransform: "none",
			fontWeight: 600,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 12,
					padding: "8px 24px",
					boxShadow: "none",
					"&:hover": {
						boxShadow: "none",
					},
				},
				containedPrimary: {
					background: "#553A41", // Dark reddish-brown
					color: "#ffffff",
					"&:hover": {
						background: "#351F21", // Hover state
					},
				},
				containedSecondary: {
					background: "#A67F8E", // Soft pinkish tone
					color: "#ffffff",
					"&:hover": {
						background: "#765661", // Hover state
					},
				},
			},
		},
	},
});

export default theme;
