import React from "react";
import { useTheme } from "@mui/material/styles";
import {
	Box,
	Card,
	CardContent,
	Typography,
	Avatar,
	useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";

const testimonials = [
	{
		id: 1,
		name: "Alex Lezcano",
		position: "CFO en Nexo Technologies",
		feedback:
			"El equipo nos brindó un nivel excepcional de servicio, y nuestras ventas han aumentado enormemente desde el lanzamiento de nuestro nuevo sitio web que desarrollaron.",
		avatar: "/assets/avatar1.jpg",
	},
	{
		id: 2,
		name: "Ishak Cumartesi",
		position: "CEO de Marsutex S.A.",
		feedback:
			"Realmente hicieron una diferencia con sus estrategias de marca. Nuestra identidad de marca ahora es clara y consistente en todas las plataformas.",
		avatar: "/assets/avatar2.jpg",
	},
	// Añadir más testimonios según sea necesario
];

const Testimonials = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));

	// Card animation variants
	const cardVariants = {
		offscreen: {
			y: 50,
			opacity: 0,
		},
		onscreen: {
			y: 0,
			opacity: 1,
			transition: {
				type: "spring",
				bounce: 0.4,
				duration: 0.8,
			},
		},
	};

	return (
		<Box
			sx={{
				px: 3,
				backgroundColor: theme.palette.background.default,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Typography variant="h2" component="h2" gutterBottom>
				¿Que Dicen Nuestros Clientes?
			</Typography>
			<Box
				component={motion.div}
				variants={cardVariants}
				initial="offscreen"
				whileInView="onscreen"
				viewport={{ once: true, amount: 0.8 }}
				sx={{
					display: "grid",
					gridTemplateColumns: matches
						? "repeat(auto-fit, minmax(300px, 1fr))"
						: "1fr",
					gap: 4,
					mt: 4,
					maxWidth: 1080,
					mx: "auto",
				}}
			>
				{testimonials.map((testimonial) => (
					<Card key={testimonial.id} raised sx={{ boxShadow: 3 }}>
						<CardContent
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: 2,
							}}
						>
							<Avatar
								src={testimonial.avatar}
								alt={testimonial.name}
								sx={{ width: 90, height: 90, mb: 2 }}
							/>
							<Typography variant="h6" component="p" fontWeight="bold">
								{testimonial.name}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								{testimonial.position}
							</Typography>
							<Typography variant="body1" sx={{ mt: 1, fontStyle: "italic" }}>
								"{testimonial.feedback}"
							</Typography>
						</CardContent>
					</Card>
				))}
			</Box>
		</Box>
	);
};

export default Testimonials;
