import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { motion } from "framer-motion";
import LogoText from "../components/logos/LogoText";
import { useTheme } from "@emotion/react";

const MotionBox = motion(Box);
const MotionTypography = motion(Typography);

const Bridge = () => {
	const theme = useTheme();
	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
	};

	return (
		<Stack
			id="about"
			mb="5rem"
			padding="5rem"
			alignItems="center"
			justifyContent="center"
		>
			<MotionTypography
				textAlign="center"
				variant="h2"
				initial="hidden"
				animate="visible"
				variants={itemVariants}
				mb={4}
				color={theme.palette.primary.main}
			>
				¿Quienes Somos?
			</MotionTypography>
			<MotionBox
				sx={{
					border: "2px solid rgba(255, 0, 0, 0.8)",
					boxShadow: "0 10px 20px rgba(255, 0, 0, 0.5)",
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					alignItems: "center",
					gap: { xs: "2rem", md: "5rem" },
					p: { xs: "2rem", md: "5rem" },
					borderRadius: "12px",

					overflow: "hidden",
				}}
				initial="hidden"
				animate="visible"
				variants={itemVariants}
			>
				<LogoText fontSize="8rem" color={theme.palette.primary.dark} />
				<MotionTypography
					variant="body"
					sx={{
						textAlign: { xs: "center", md: "left" },
						textShadow: "0px 1px 2px rgba(255, 0, 0, 0.4)",
					}}
					variants={itemVariants}
				>
					Combinamos arte y tecnología para crear sitios web excepcionales que
					capturan la esencia de tu marca. Nos especializamos en desarrollo y
					diseño web, ofreciendo soluciones innovadoras que potencian la
					presencia online de nuestros clientes. Nuestro equipo experto está
					dedicado a construir experiencias digitales memorables que no solo
					lucen impresionantes, sino que también funcionan a la perfección.
				</MotionTypography>
			</MotionBox>
		</Stack>
	);
};

export default Bridge;
