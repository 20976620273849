import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	Button,
	useTheme,
	IconButton,
	Drawer,
	Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoText from "../components/logos/LogoText";

const scrollToId = (id) => {
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView({ behavior: "smooth" });
	}
};

const Navbar = () => {
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<Box
			onClick={handleDrawerToggle}
			sx={{
				textAlign: "center",
				backgroundColor: theme.palette.primary.dark,
				height: "100vh",
			}}
		>
			<IconButton
				color="inherit"
				aria-label="open drawer"
				edge="end"
				onClick={handleDrawerToggle}
				sx={{ display: { xs: "block", md: "none" } }}
			>
				<MenuIcon />
			</IconButton>
			{["Home", "About", "Services", "Precios", "Contact"].map((text) => (
				<Button
					key={text}
					onClick={() => scrollToId(text.toLowerCase())}
					sx={{
						color: "white",

						display: "block",
						width: "100%",
						padding: theme.spacing(1),
					}}
				>
					{text}
				</Button>
			))}
		</Box>
	);

	return (
		<AppBar
			position="sticky"
			sx={{
				background: theme.palette.primary.dark,
				color: "white",
				overflowX: "hidden",
			}}
		>
			<Toolbar
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<LogoText fontSize={"2rem"} letterSpacing={"-2px"} color="white" />
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="end"
					onClick={handleDrawerToggle}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<MenuIcon />
				</IconButton>
				<Box sx={{ display: { xs: "none", md: "block" } }}>
					{["Home", "About", "Services", "Precios", "Contact"].map((text) => (
						<Button
							key={text}
							variant="text"
							onClick={() => scrollToId(text.toLowerCase())}
							sx={{
								color: "white",
								margin: "0 12px",
								"&:hover": {
									backgroundColor: theme.palette.primary.dark,
									color: theme.palette.primary.light,
								},
							}}
						>
							{text}
						</Button>
					))}
				</Box>
			</Toolbar>
			<Drawer
				anchor="right"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				{drawer}
			</Drawer>
		</AppBar>
	);
};

export default Navbar;
