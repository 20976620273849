import React from "react";
import { Box, Typography, IconButton, Link } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTheme } from "@emotion/react";

const Footer = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				padding: "20px",
				background: theme.palette.primary.dark,
				color: "white",
			}}
		>
			<Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
				Bridge Digital Solutions
			</Typography>
			<Box
				sx={{
					display: "flex",
					mb: 2,
				}}
			>
				<Link
					href="#"
					sx={{ color: "white", margin: "0 10px", textDecoration: "none" }}
				>
					Home
				</Link>
				<Link
					href="#aboutus"
					sx={{ color: "white", margin: "0 10px", textDecoration: "none" }}
				>
					About
				</Link>
				<Link
					href="#services"
					sx={{ color: "white", margin: "0 10px", textDecoration: "none" }}
				>
					Services
				</Link>
				<Link
					href="mailto:eyal@nexo.com.pa"
					sx={{ color: "white", margin: "0 10px", textDecoration: "none" }}
				>
					Contact
				</Link>
			</Box>
			<Box
				sx={{
					display: "flex",
					mb: 2,
				}}
			>
				<IconButton href="https://instagram.com" sx={{ color: "white" }}>
					<InstagramIcon />
				</IconButton>
				<IconButton href="https://twitter.com" sx={{ color: "white" }}>
					<TwitterIcon />
				</IconButton>
				<IconButton href="https://facebook.com" sx={{ color: "white" }}>
					<FacebookIcon />
				</IconButton>
				<IconButton href="https://linkedin.com" sx={{ color: "white" }}>
					<LinkedInIcon />
				</IconButton>
			</Box>
			<Typography variant="body2">
				© {new Date().getFullYear()} Bridge Digital Solutions. All rights
				reserved.
			</Typography>
		</Box>
	);
};

export default Footer;
