import React, { useState } from "react";
import {
	Card,
	Box,
	Typography,
	TextField,
	Button,
	Grid,
	useMediaQuery,
	useTheme,
	Select,
	MenuItem,
} from "@mui/material";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";

const ContactUs = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	const [success, setSuccess] = useState(null);

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_EMAILJS_SERVICE_ID,
				process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
				e.target,
				process.env.REACT_APP_EMAILJS_USER_ID
			)
			.then(
				(result) => {
					if (result) {
						setSuccess(true);
					}
				},
				(error) => {
					console.log(error.text);
					setSuccess(null);
					alert("Failed to send the message, please try again.");
				}
			);
	};

	return (
		<Box
			id="contact"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				padding: theme.spacing(3),
			}}
		>
			<Card
				component={motion.div}
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.5 }}
				sx={{
					maxWidth: 600,
					width: "100%",
					padding: theme.spacing(4),
					boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
					borderRadius: theme.spacing(2),
				}}
			>
				<Typography
					variant="h4"
					component="h1"
					gutterBottom
					sx={{
						fontWeight: "bold",
						textAlign: "center",
						color: theme.palette.primary.main,
					}}
				>
					Contactanos
				</Typography>
				<Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
					Estaremos encantados de responder cualquier pregunta que tengas o
					proporcionarte un presupuesto. Solo envíanos un mensaje en el
					formulario a continuación.
				</Typography>
				<form onSubmit={sendEmail}>
					<Grid container spacing={2}>
						{!success ? (
							<>
								<Grid item xs={12}>
									<TextField
										color="secondary"
										fullWidth
										name="user_name"
										label="Nombre"
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										color="secondary"
										fullWidth
										name="user_email"
										label="Email"
										variant="outlined"
										type="email"
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										color="secondary"
										fullWidth
										name="service_type"
										label="Tipo de Servicio"
										variant="outlined"
										defaultValue=""
									>
										<MenuItem value="Basico">Basico</MenuItem>
										<MenuItem value="Profesional">Profesional</MenuItem>
										<MenuItem value="Hablar Con Ventas">
											Hablar Con Ventas
										</MenuItem>
									</Select>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										color="secondary"
										name="message"
										label="Mensaje"
										variant="outlined"
										multiline
										rows={4}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										component={motion.button}
										whileHover={{ scale: 1.05 }}
										whileTap={{ scale: 0.95 }}
										variant="contained"
										color="primary"
										fullWidth
										type="submit"
									>
										Enviar Mensaje
									</Button>
								</Grid>
							</>
						) : (
							<Grid item xs={12}>
								<Typography sx={{ textAlign: "center", fontWeight: 700 }}>
									El mensaje fue enviado con exito! Te contactaremos lo antes
									posible.
								</Typography>
							</Grid>
						)}
					</Grid>
				</form>
			</Card>
		</Box>
	);
};

export default ContactUs;
