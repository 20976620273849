import React from "react";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Typography,
	Button,
	Stack,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const pricingTiers = [
	{
		title: "Básico",
		price: "$599",
		features: [
			"Página Web Básica",
			"Asesoria de Branding",
			"5 Páginas Incluidas",
			"Soporte 24/7",
			"Acceso a la Comunidad",
		],
		recommended: false,
	},
	{
		title: "Profesional",
		price: "$999",
		features: [
			"Página Web Profesional",
			"Rebranding",
			"10 Páginas Incluidas",
			"Soporte 24/7",
			"Soporte Prioritario",
			"Acceso Directo al Desarrollador",
		],
		recommended: true,
	},
	{
		title: "Empresa",
		price: "Cotizar Con Ventas",
		features: [
			"Proyectos Personalizados según necesidades",
			"Branding Profesional Personalizado",
			"Soporte 24/7",
			"Almacenamiento Ilimitado",
			"Plataformas Integradas para Ecommerce, Administración y más",
		],
		recommended: false,
	},
];

const PricingComponent = () => {
	const theme = useTheme();

	return (
		<Box id="precios" mt={"5rem"}>
			<Typography
				color={theme.palette.primary.main}
				textAlign="center"
				variant="h2"
			>
				Nuestros Precios
			</Typography>

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					position: "relative",
					mt: 8,
					gap: "8px",
				}}
			>
				{pricingTiers.map((tier, index) => (
					<Card
						sx={{
							maxWidth: 345,
							width: "35rem",
							borderRadius: tier.recommended ? "16px" : "8px",
							boxShadow: tier.recommended
								? "0 10px 20px rgba(255, 0, 0, 0.5)"
								: "0 5px 10px rgba(0, 0, 0, 0.2)",
							border: tier.recommended
								? "2px solid rgba(255, 0, 0, 0.8)"
								: "1px solid #ddd",
							transform: tier.recommended ? "scale(1.05)" : "none",
							zIndex: tier.recommended ? 2 : 1,
							backgroundColor: tier.recommended
								? "rgba(255, 235, 238, 0.5)"
								: "#fff",
							"&:hover": {
								boxShadow: "0 12px 24px rgba(255, 0, 0, 0.5)",
							},
							position: tier.recommended ? "relative" : "initial",
						}}
						key={tier.title}
					>
						<CardHeader
							title={tier.title}
							sx={{
								backgroundColor: tier.recommended
									? "rgba(255, 0, 0, 0.8)"
									: "rgba(0, 0, 0, 0.08)",
								color: tier.recommended ? "#fff" : "#000",
							}}
							titleTypographyProps={{ align: "center" }}
							action={
								tier.recommended ? (
									<StarBorderIcon sx={{ fill: "gold" }} />
								) : null
							}
							subheaderTypographyProps={{
								color: "white",
							}}
							subheader={tier.recommended ? "Mas Popular" : null}
						/>
						<CardContent>
							<Typography
								variant="h5"
								align="center"
								sx={{
									mb: 2,
									color: tier.recommended ? "text.primary" : "text.secondary",
								}}
							>
								{tier.price}
							</Typography>
							<Stack spacing={2}>
								{tier.features.map((feature, index) => (
									<Typography variant="subtitle1" align="center" key={index}>
										{feature}
									</Typography>
								))}
							</Stack>
							<Stack direction="row" justifyContent="center" mt={4}>
								<Button
									variant="contained"
									href={"#contact"}
									color={tier.recommended ? "error" : "primary"}
								>
									Escoger el plan
								</Button>
							</Stack>
						</CardContent>
					</Card>
				))}
			</Box>
		</Box>
	);
};

export default PricingComponent;
