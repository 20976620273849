import React from "react";
import { motion } from "framer-motion";
import { Typography, Button, Box, useTheme } from "@mui/material";
import LogoText from "../components/logos/LogoText";

const HeroPage = () => {
	const theme = useTheme();

	const heroVariants = {
		initial: { opacity: 0, y: 30 },
		animate: {
			opacity: 1,
			y: 0,
			transition: { duration: 0.8, ease: "easeOut" },
		},
	};

	return (
		<Box
			id="home"
			sx={{
				height: "100vh",
				textAlign: "center",
				backgroundImage: 'url("./assets/background_hero.webp")',
				backgroundSize: "cover",

				backgroundPosition: "center", // Adjusts the position of the background image
				position: "relative",
				overflow: "hidden",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",

					height: "100vh",
					width: "100vw",
				}}
			>
				<motion.div initial="initial" animate="animate">
					<Box mb={4}>
						<LogoText
							fontSize={"8rem"}
							letterSpacing={"-0.07em"}
							shadow={"0px 4px 8px rgba(0, 0, 0, 0.3)"}
							color="#ffffff"
						/>
					</Box>
					<motion.div variants={heroVariants}>
						<Typography
							variant="h2"
							sx={{
								fontWeight: 700,
								fontSize: "4rem",
								color: "#ffffff",
								textShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
								mb: 2,
							}}
						>
							Potenciando la Innovación
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1.5rem",
								color: "#ffffff",
								textShadow: "0px 2px 8px  rgba(0, 0, 0, 0.1)",
								mb: 3,
							}}
						>
							Descubre el futuro de la tecnología con nosotros.
						</Typography>
					</motion.div>{" "}
					<Button
						variant="contained"
						size="large"
						sx={{
							borderRadius: 12,
							padding: "15px 40px",
							fontSize: "1.2rem",
							fontWeight: "bold",
							background: theme.palette.primary.dark,
							color: theme.palette.background.paper,
							"&:hover": {
								background: theme.palette.primary.light,
							},
							boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.25)",
						}}
					>
						Agenda Una Asesoria
					</Button>
				</motion.div>
			</Box>
		</Box>
	);
};

export default HeroPage;
