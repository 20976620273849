import React from "react";
import {
	Box,
	Typography,
	Card,
	Grid,
	CardMedia,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import {
	ShoppingCartIcon,
	DesignOutlinedIcon,
	PhoneSettingsIcon,
	CodeIcon,
} from "./svgs/ThinnerCustomIcon.jsx";

const services = [
	{
		title: "Responsive Web Design",
		description:
			"Ensure your site looks great on any device, from desktop to mobile.",
	},
	{
		title: "E-commerce Solutions",
		description:
			"Custom shopping experiences that drive sales and enhance user engagement.",
	},
	{
		title: "Content Management Systems",
		description:
			"Empower your team to publish content easily with robust CMS solutions.",
	},
	{
		title: "Web Optimization",
		description: "Enhance your website's performance, accessibility, and SEO.",
	},
];

const AboutUs = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));

	const cardHover = {
		scale: 1.05,
		transition: { duration: 0.3 },
	};

	return (
		<Box
			id="services"
			sx={{
				py: 8,
				px: 4,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				backgroundColor: theme.palette.background.default,
			}}
		>
			<Typography
				variant="h2"
				component="h2"
				sx={{
					mb: 5,
					fontWeight: "bold",
					textAlign: "center",
					color: theme.palette.text.primary,
				}}
			>
				¿Que Ofrecemos?
			</Typography>
			<Grid container spacing={4} justifyContent="center">
				{services.map((service, index) => (
					<Grid item xs={12} sm={6} md={3} key={service.title}>
						<motion.div
							whileHover={cardHover}
							style={{
								display: "flex",

								flexDirection: "column",
								alignItems: "center",

								borderRadius: "16px",
							}}
						>
							<Card
								raised
								sx={{
									width: "100%",
									boxShadow: "0 10px 20px rgba(255, 0, 0, 0.2)",
									display: "flex",
									height: "25rem",
									flexDirection: "column",
									alignItems: "center",
									py: "1rem",
								}}
							>
								<CardMedia
									component="img"
									src={`/assets/card${index + 1}.svg`}
									alt={"card image " + index + 1}
									sx={{
										fontSize: "8rem",

										color: theme.palette.primary.main,
										width: "14rem",
									}}
								/>

								<Box sx={{ p: 2 }}>
									<Typography
										variant="h6"
										sx={{ fontWeight: "medium", textAlign: "center" }}
									>
										{service.title}
									</Typography>
									<Typography variant="body2" sx={{ textAlign: "center" }}>
										{service.description}
									</Typography>
								</Box>
							</Card>
						</motion.div>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default AboutUs;
